<template>
  <div class="flex justify-center items-center">
    <q-carousel
      :class="`w-full ${row === 1 ? 'h-auto' : ''}`"
      v-model="state.value"
      animated
      transition-prev="slide-right"
      transition-next="slide-left"
      swipeable
      control-color="grey-6"
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
      padding
      arrows
      :autoplay="autoplay"
      :infinite="infinite"
    >
      <q-carousel-slide
        v-for="(array, i) in regionsArray"
        :key="i"
        :name="i"
        :class="`p-0 grid grid-rows-${row} ${gridClass}`"
      >
        <div v-for="(region, j) in array" :key="j" class="px-4">
          <q-card :flat="flat">
            <q-img
              :src="region.image"
              :alt="region.name"
              :ratio="ratio"
              :initial-ratio="ratio"
              :height="imageHeight"
              loading="lazy"
              @click="
                () => {
                  if (showDialog) {
                    let key = 0;
                    regionsArray.slice(0, i).forEach((t) => {
                      key += t.length;
                    });

                    state.key = key + j;
                    state.visible = true;
                  }
                }
              "
            >
              <template #loading>
                <q-spinner-dots color="primary" size="1.5rem" />
              </template>
            </q-img>

            <q-card-section v-if="showDesc">
              <div
                class="flex justify-center text-center"
                style="height: 4.5rem"
              >
                {{ region.name }}
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-carousel-slide>
    </q-carousel>
    <q-dialog class="overflow-hidden" v-model="state.visible" maximized>
      <div class="p-16">
        <q-carousel
          class="w-full h-full overflow-hidden bg-transparent"
          v-model="state.key"
          transition-prev="jump-right"
          transition-next="jump-left"
          swipeable
          animated
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          thumbnails
          infinite
        >
          <template v-for="(region, i) in regions" :key="i">
            <q-carousel-slide :name="i" :img-src="region.image" />
          </template>

          <template #navigation-icon="{ active, onClick }">
            <q-btn
              v-if="active"
              class="bg-green-600 w-14 h-2"
              padding="0"
              dense
              @click="onClick"
            />
            <q-btn
              v-else
              class="bg-gray-500 w-14 h-2"
              padding="0"
              dense
              @click="onClick"
            />
          </template>
        </q-carousel>
        <div class="absolute top-8 right-8">
          <q-btn
            size="lg"
            color="white"
            text-color="primary"
            icon="mdi-close"
            no-caps
            circle
            round
            v-close-popup
          />
        </div>
      </div>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, nextTick } from 'vue';
import { RegionDto } from 'src/api/types';
import useWindowResize from 'src/utils/useWindowResize';

export interface SvThumbnailProps {
  regions: RegionDto[];
  gridClass: string;
  row?: number;
  ratio?: string;
  imageHeight?: string;
  flat?: boolean;
  showDesc?: boolean;
  showDialog?: boolean;
  autoplay?: boolean | number;
  infinite?: boolean | number;
}

const props = withDefaults(defineProps<SvThumbnailProps>(), {
  regions: () => [],
  row: 1,
  ratio: '4/3',
  imageHeight: '200px',
  flat: true,
  showDesc: true,
  showDialog: true,
  autoplay: false,
  infinite: false,
});

const gridCols = ref('');
useWindowResize(async () => {
  await nextTick();
  const w = window.innerWidth;
  gridCols.value =
    w >= 1536
      ? '2xl'
      : w >= 1280
      ? 'xl'
      : w >= 1024
      ? 'lg'
      : w >= 768
      ? 'md'
      : w >= 640
      ? 'sm'
      : '';
});

const regionsArray = computed(() => {
  const reg = new RegExp(' ' + gridCols.value + ':grid-cols-(\\d)').exec(
    ' ' + props.gridClass
  );
  const cols = reg?.[1] ? parseInt(reg?.[1]) : 2;

  const result = [];
  let i = 0;
  while (true) {
    const array = props.regions.slice(i, i + cols * props.row);
    if (array.length > 0) {
      result.push(array);
      i += cols * props.row;
    } else break;
  }
  return result;
});

const state = reactive<{
  key?: number;
  value: number;
  visible: boolean;
}>({
  value: 0,
  visible: false,
});
</script>

<style scoped>
.q-carousel :deep(.q-carousel__arrow .q-icon) {
  font-size: 3.5rem;
  /* color: rgb(34 197 94); */
}

.q-carousel__slide {
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
