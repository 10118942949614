<template>
  <q-page>
    <!-- <template v-for="item in [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44,
  48, 52, 56, 60, 64, 72, 80, 96,
]" :key="item">
      {{ `
.-gap-x-${item} > * {
  margin-left: ${0.125 * item}rem;
  margin-right: ${0.125 * item}rem;
}
.-gap-x-${item} > *:first-child {
  margin-left: 0;
  margin-right: ${0.125 * item * 2}rem;
}
.-gap-x-${item} > *:last-child {
  margin-left: ${0.125 * item * 2}rem;
  margin-right: 0;
}
.-gap-y-${item} > * {
  margin-top: ${0.125 * item}rem;
  margin-bottom: ${0.125 * item}rem;
}
.-gap-y-${item} > *:first-child {
  margin-top: 0;
  margin-bottom: ${0.125 * item * 2}rem;
}
.-gap-y-${item} > *:last-child {
  margin-top: ${0.125 * item * 2}rem;
  margin-bottom: 0;
}
.-gap-${item} > * {
  margin-left: ${0.125 * item}rem;
  margin-right: ${0.125 * item}rem;
  margin-top: ${0.125 * item}rem;
  margin-bottom: ${0.125 * item}rem;
}
      ` }}
    </template> -->

    <!-- <q-card
      class="absolute z-50 rounded-xl overflow-hidden"
      style="top: calc((100vh - 200px) / 4); right: calc((100vh - 200px) / 6)"
      v-show="videoPlayerVisible"
      @mouseenter="carouselAutoplay = false"
      @mouseover="carouselAutoplay = 8000"
    >
      <video-player
        class="video-player vjs-big-play-centered"
        style="
          width: calc((100vh - 200px) / 2.2 / 9 * 16);
          height: calc((100vh - 200px) / 2.2);
        "
        src="/videos/1106-vf1080p30.mp4?d=230809"
        poster="videos/video.jpg?d=230809"
        crossorigin="anonymous"
        playsinline
        :muted="!videoFullscreen"
        :controls="videoFullscreen"
        :volume="0.6"
        :playback-rates="[0.7, 1.0, 1.5, 2.0]"
        @mounted="handleMounted($event)"
        @fullscreenchange="handleFullscreenChanged"
      >
      </video-player>
    </q-card>

    <q-btn
      v-show="videoPlayerVisible"
      class="absolute z-50 w-40"
      style="
        background-color: #0e6ad1;
        color: #fff;
        top: calc((100vh - 200px) * 2 / 3);
        left: 110px;
      "
      size="lg"
      @click="handlePlay"
      >播放视频</q-btn
    > -->

    <template v-for="region in page.regions" :key="region.id">
      <sv-carousel
        v-if="region.type === 'carousel' && region.children?.length"
        :regions="region.children!"
        height="calc(100vh - 12rem)"
        class="w-full"
        transition-prev="jump-right"
        transition-next="jump-left"
        swipeable
        animated
        control-color="green-7"
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
        navigation-icon="mdi-radiobox-blank"
        navigation
        padding
        arrows
        infinite
      >
        <template #navigation-icon="{ active, onClick }">
          <q-btn
            v-if="active"
            class="bg-green-600 w-14 h-2"
            padding="0"
            dense
            @click="onClick"
          />
          <q-btn
            v-else
            class="bg-gray-500 w-14 h-2"
            padding="0"
            dense
            @click="onClick"
          />
        </template>
      </sv-carousel>
      <!-- <sv-carousel
        v-if="region.type === 'carousel' && region.children?.length"
        :regions="region.children!"
        :autoplay="carouselAutoplay"
        height="calc(100vh - 12rem)"
        class="w-full"
        transition-prev="jump-right"
        transition-next="jump-left"
        swipeable
        animated
        control-color="green-7"
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
        navigation-icon="mdi-radiobox-blank"
        navigation
        padding
        arrows
        infinite
        @update:model-value="handleCarouseUpdated"
      >
        <template #navigation-icon="{ active, onClick }">
          <q-btn
            v-if="active"
            class="bg-green-600 w-14 h-2"
            padding="0"
            dense
            @click="onClick"
          />
          <q-btn
            v-else
            class="bg-gray-500 w-14 h-2"
            padding="0"
            dense
            @click="onClick"
          />
        </template>
      </sv-carousel> -->

      <div
        v-if="
          region.type === 'product-list' && region.extraProperties?.linkPageId
        "
        class="flex justify-center bg-sky-50"
      >
        <div class="container lg:px-8 xl:px-0">
          <sv-product-tabs
            v-if="!currentLang"
            class="mt-8 mb-16"
            :pages="productPages(region.extraProperties.linkPageId)"
          ></sv-product-tabs>
          <SvProductENTabs
            v-else-if="currentLang"
            class="mt-8 mb-20"
            :pages="productPages(region.extraProperties.linkPageId)"
          ></SvProductENTabs>
        </div>
      </div>

      <div
        v-if="region.type === 'document-list'"
        class="flex justify-center pt-16 pb-24"
      >
        <div class="container lg:px-8 xl:px-0">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <img
                v-if="region.icon"
                :src="region.icon"
                class="w-8 h-8 mx-2 inline"
                :alt="region.name"
              />
              <span class="text-2xl">{{ region.name }}</span>
            </div>
            <router-link
              to="/news"
              class="flex items-center -gap-x-2 text-green-600"
            >
              <span>{{ $t('Index.more') }}</span>
              <q-icon size="20px" name="mdi-arrow-right-drop-circle-outline" />
            </router-link>
          </div>
          <sv-document-cards
            :region="region"
            :articles="articles"
          ></sv-document-cards>
        </div>
      </div>

      <div
        v-if="region.type === 'image-list' && region.children?.length"
        class="flex justify-center py-8"
      >
        <div class="container lg:px-8 xl:px-0">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <img
                v-if="region.icon"
                :src="region.icon"
                class="w-8 h-8 mx-2 inline"
                :alt="region.name"
              />
              <span class="text-2xl w-">{{ region.name }}</span>
            </div>
          </div>
          <sv-thumbnail
            v-if="region.type === 'image-list' && region.children?.length"
            :regions="region.children"
            grid-class="2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2"
            imageHeight="108px"
            ratio="16/9"
            flat
            :row="3"
            :show-desc="false"
            :show-dialog="false"
            autoplay
          >
          </sv-thumbnail>
        </div>
      </div>
    </template>
  </q-page>
</template>

<script lang="ts">
import { usePageStore } from 'stores/modules/page';
import { useArticleStore } from 'stores/modules/article';

export default {
  async preFetch({ store, currentRoute }) {
    const pageStore = usePageStore(store);
    await pageStore.fetch({ url: currentRoute.path });

    const articleStore = useArticleStore(store);
    await articleStore.fetchList({
      isActive: true,
      skipCount: 0,
      maxResultCount: 10,
    });
  },
};
</script>

<script setup lang="ts">
import { ref, computed, shallowRef } from 'vue';
import { useMeta } from 'quasar';
import { treeToList } from 'src/utils/tree';
import SvCarousel from 'src/components/SvCarousel.vue';
import SvProductTabs from 'src/components/SvProductTabs.vue';
import SvProductENTabs from 'src/components/SvProductENTabs.vue';
import SvDocumentCards from 'src/components/SvDocumentCards.vue';
import SvThumbnail from 'src/components/SvThumbnail.vue';
import { useMetaStore } from 'src/stores/modules/meta';
import { useI18n } from 'vue-i18n';
// import { VideoJsPlayer } from 'video.js';
// import { VideoPlayer } from '@videojs-player/vue';

// const videoPlayerVisible = ref<boolean>(false);
// const videoFullscreen = ref<boolean>(false);
// const carouselAutoplay = ref<boolean | number>(8000);

// const player = shallowRef<VideoJsPlayer>();
// const handleMounted = (payload: any) => {
//   player.value = payload.player;
//   player.value?.play();
// };

// const handlePlay = () => {
//   const button = document.getElementsByClassName(
//     'vjs-fullscreen-control'
//   )[0] as HTMLElement;
//   button.click();
// };

// const handleFullscreenChanged = () => {
//   videoFullscreen.value = !videoFullscreen.value;
// };

// const handleCarouseUpdated = (value: string | number) => {
//   videoPlayerVisible.value = value === 1;
// };

const { locale } = useI18n();
const currentLang = computed(() => locale.value === 'en');

const pageStore = usePageStore();
const page = computed(() => pageStore.page);

const metaStore = useMetaStore();
useMeta(
  Object.assign({}, metaStore.meta, {
    title: page.value.title,
    titleTemplate: (title: string) => `${title} - ${metaStore.meta.title}`,
  })
);

const articleStore = useArticleStore();
const articles = computed(() => articleStore.articles);

const menus = computed(() => pageStore.menus);
const productPages = (id: string) => {
  const root = treeToList(menus.value, { pid: 'parentId' }).filter(
    (t: any) => t.id === id
  )[0];
  return root?.children ?? [];
};
</script>

<style scoped>
.q-carousel :deep(.q-carousel__arrow .q-icon) {
  font-size: 3.5rem;
  /* color: rgb(34 197 94); */
}

.button {
  position: absolute;
  top: calc(100vw * 4.5 / 16 + 50px);
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.opacity-08 {
  opacity: 0.8;
}

.vjs-custom-skin :deep(.video-js .vjs-big-play-button) {
  border-radius: 50% !important;
  width: 3em !important;
  height: 3em !important;
  font-size: 3.5em;
  line-height: 3em !important;
}

.vjs-control-text {
  display: none !important;
}
</style>
